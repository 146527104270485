body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.errorInput {
  color: red;
  font-weight: 400;
}
ul.cats-list {
  height: 200px;
  overflow-y: scroll;
}
.react-time-picker__wrapper {
  width: 100%;
  padding: 5px;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6f6b7d;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: var(--bs-border-width) solid #dbdade !important;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.demo-inline-spacing > img {
  max-width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.demo-inline-spacing {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #dddddd;
  padding: 4px;
}
svg.react-time-picker__clock-button__icon.react-time-picker__button__icon,
svg.react-time-picker__clear-button__icon.react-time-picker__button__icon {
  width: 14px;
}
.react-time-picker__inputGroup {
  display: flex;
  font-size: 14px;
  align-items: center;
}
.react-time-picker__button {
  padding: 4px 4px;
}

.demo-inline-spacing:has(> video) {
  width: 250px;
  height: 150px;
}
.demo-inline-spacing > video {
  width: 100%;
  height: 100%;
}
.timingmenues.col-sm-2 {
  width: -moz-fit-content;
  width: fit-content;
  min-width: 16.666%;
}
