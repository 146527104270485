.toggle-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  gap: 10px;
}

.toggle-button .toggle-circle {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  transition: background-color 0.3s ease;
}

.toggle-button .toggle-circle::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-button .toggle-circle.toggled {
  background-color: #007bff;
}

.toggle-button .toggle-circle.toggled::before {
  transform: translateX(20px);
}

.toggle-button .toggle-label {
  margin-left: 10px;
  font-size: 16px;
  color: #333;
}
