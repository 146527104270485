.page-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  width: 100%;
  height: 100vh;
  padding: 0 100px;
}

.page-container .page-list-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  border-bottom: 2px solid;
  border-image: linear-gradient(90deg, var(--slate-color), var(--dark-color)) 1;
}

.page-container .page-list-top .page-list-heading {
  display: flex;
  gap: 10px;
  align-items: center;
}

.page-container .page-list-top .page-list-heading img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.page-container .page-list-top .page-list-heading h1 {
  font-size: 26px;
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
  margin: 0;
  display: flex;
  gap: 10px;
  align-items: center;
}

.page-container .page-list-top .page-list-heading span {
  padding: 5px 5px 5px 15px;
  border-left: 2px solid;
  border-image: linear-gradient(var(--slate-color), var(--dark-color)) 1;
}

.page-container .dynamic-page-container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.page-container .dynamic-page-container .page-component-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f4f4f4;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.page-container .dynamic-page-container .page-component-panel h3 {
  margin: 0;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .page-components-container {
  display: flex;
  flex-direction: column;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .page-components-container
  .image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .page-components-container
  .image-uploader
  img {
  width: 200px;
  height: 150px;
  object-fit: contain;
  cursor: grab;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .page-components-container
  .heading-uploader {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  gap: 10px;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .page-components-container
  .heading-uploader
  input[type="text"],
.page-container
  .dynamic-page-container
  .page-component-panel
  .page-components-container
  .heading-uploader
  input[type="number"] {
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 6px;
  outline: none;
  background: #fff;
  padding: 10px 15px;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .page-components-container
  .heading-uploader
  input[type="text"] {
  width: 100%;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .page-components-container
  .heading-uploader
  input[type="number"] {
  width: 80px;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .page-components-container
  .description-uploader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .page-components-container
  .description-uploader
  textarea {
  width: 100%;
  border: 1px solid #ddd;
  background: #fff;
  outline: none;
  padding: 10px;
  border-radius: 6px;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .form-list-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .form-list-container
  h3 {
  margin: 0;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .form-list-container
  .form-draggable-item {
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .form-list-container
  .copy-icon {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .form-list-container
  .form-draggable-item
  .form-component-icon-label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .form-list-container
  .form-draggable-item
  .form-component-icon-label
  svg {
  font-size: 20px;
  color: var(--slate-color);
}

.page-container
  .dynamic-page-container
  .page-component-panel
  .form-list-container
  .form-draggable-item
  .form-component-icon-label
  span {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.page-container .dynamic-page-container .page-drop-container {
  width: 75%;
  background-color: #eaeaea;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
}

.page-container .dynamic-page-container .page-drop-container-heading {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.page-container
  .dynamic-page-container
  .page-drop-container-heading
  .page-drop-heading-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.page-container
  .dynamic-page-container
  .page-drop-container-heading
  .page-drop-heading-left
  h4 {
  margin: 0;
}

.page-container
  .dynamic-page-container
  .page-drop-container-heading
  .page-drop-heading-left
  input {
  width: 250px;
  height: 40px;
  background: #fff;
  border: 1px solid rgb(119, 191, 255);
  border-radius: 10px;
  padding: 10px 15px;
  outline: none;
}

.page-container
  .dynamic-page-container
  .page-drop-container-heading
  .page-drop-heading-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.page-container
  .dynamic-page-container
  .page-drop-container-heading
  .page-drop-heading-right
  p {
  display: flex;
  align-items: center;
  font-size: 40px;
  gap: 10px;
  margin: 0;
  cursor: pointer;
  color: inherit;
}

.page-container
  .dynamic-page-container
  .page-drop-container-heading
  .page-drop-heading-right
  p
  span {
  font-size: 18px;
}

.page-container
  .dynamic-page-container
  .page-drop-container-heading
  .page-drop-heading-right
  p:hover {
  color: var(--magenta-bright-color);
}

.placeholder-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #888;
  text-align: center;
  pointer-events: none;
}

.page-dropped-item {
  margin: 5px;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  position: relative;
}

.page-dropped-item .drag-icon,
.page-dropped-item .trash-icon {
  position: absolute;
  top: 5px;
  z-index: 10;
  font-size: 20px;
  color: #555;
  cursor: pointer;
}

.page-dropped-item .drag-icon {
  left: 10px;
  color: var(--primary-color);
}

.page-dropped-item .trash-icon {
  right: 10px;
  color: var(--danger-color);
}

.page-dropped-item .resize-handle {
  width: 10px;
  height: 10px;
  background-color: blue;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: nwse-resize;
}

.page-dropped-item.dragging .drag-icon,
.page-dropped-item.dragging .trash-icon {
  z-index: 20;
}

.page-dropped-item .page-component-form-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.page-dropped-item .page-component-form-content h1 {
  font-size: 20px;
}

.page-dropped-item .page-component-form-content form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 600px;
}

.page-dropped-item .page-component-form-content form input[type="email"],
.page-dropped-item .page-component-form-content form input[type="text"],
.page-dropped-item .page-component-form-content form input[type="number"],
.page-dropped-item .page-component-form-content form input[type="password"],
.page-dropped-item .page-component-form-content form input[type="file"],
.page-dropped-item .page-component-form-content form input[type="date"],
.page-dropped-item .page-component-form-content form input[type="time"],
.page-dropped-item .page-component-form-content form textarea,
.page-dropped-item .page-component-form-content form select {
  padding: 12px;
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  transition: border-color 0.3s ease;
}

.page-dropped-item .page-component-form-content form input[type="email"]:focus,
.page-dropped-item .page-component-form-content form input[type="text"]:focus,
.page-dropped-item .page-component-form-content form input[type="number"]:focus,
.page-dropped-item
  .page-component-form-content
  form
  input[type="password"]:focus,
.page-dropped-item .page-component-form-content form input[type="file"]:focus,
.page-dropped-item .page-component-form-content form input[type="date"]:focus,
.page-dropped-item .page-component-form-content form input[type="time"]:focus,
.page-dropped-item .page-component-form-content form textarea:focus,
.page-dropped-item .page-component-form-content form select:focus {
  border-color: #0677c8;
}

.page-dropped-item .page-component-form-content form select {
  width: 300px;
}

.page-dropped-item .page-component-form-content form input[type="date"],
.page-dropped-item .page-component-form-content form input[type="time"] {
  width: 300px;
}

.page-dropped-item .page-component-form-content form .mobile-number-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.page-dropped-item
  .page-component-form-content
  form
  .mobile-number-container
  select {
  width: max-content;
}

.page-dropped-item .trash-icon,
.page-dropped-item .page-component-form-content .trash-icon {
  color: var(--red-color);
  font-size: 20px;
  cursor: pointer;
}

.page-dropped-item .page-component-form-content form .dragged-form {
  height: max-content;
}

.page-dropped-item .page-component-form-content form .page-radio-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
}

.page-dropped-item .page-component-form-content form .image-show-container {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.page-dropped-item
  .page-component-form-content
  form
  .checkbox-container
  input[type="checkbox"] {
  width: 30px;
}

.page-dropped-item
  .page-component-form-content
  form
  .page-radio-container
  .page-option-text-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.page-dropped-item
  .page-component-form-content
  form
  .page-radio-container
  .page-option-text-container
  input {
  width: 40px;
}
