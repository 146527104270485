.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  width: 100%;
  height: 100vh;
}

.form-container .form-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  border-bottom: 2px solid;
  border-image: linear-gradient(90deg, #738bae, #343a40) 1;
}

.form-container .form-top .form-heading {
  display: flex;
  gap: 10px;
  align-items: center;
}

.form-container .form-top .form-heading img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.form-container .form-top .form-heading h1 {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 26px;
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
  margin-bottom: 0;
}

.form-container .form-top .form-heading span {
  padding: 5px 5px 5px 15px;
  border-left: 2px solid;
  border-image: linear-gradient(#738bae, #343a40) 1;
}

.form-container .app-container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.form-container .app-container .components-panel {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 25%;
  background-color: #f4f4f4;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.form-container .app-container .components-panel h3 {
  margin-bottom: 15px;
}

.form-container .app-container .components-panel .components-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.form-container
  .app-container
  .components-panel
  .components-grid
  .draggable-item {
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: grab;
  display: flex;
  align-items: center;
}

.form-container
  .app-container
  .components-panel
  .components-grid
  .draggable-item
  .component-icon-label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.form-container
  .app-container
  .components-panel
  .components-grid
  .draggable-item
  .component-icon-label
  svg {
  font-size: 20px;
  color: #738bae;
}

.form-container
  .app-container
  .components-panel
  .components-grid
  .draggable-item
  .component-icon-label
  span {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.form-container .app-container .components-panel .created-forms-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-container .app-container .components-panel .created-forms-container h3 {
  margin-top: 20px;
}

.form-container
  .app-container
  .components-panel
  .created-forms-container
  .saved-forms {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.form-container
  .app-container
  .components-panel
  .created-forms-container
  .saved-forms
  h4 {
  font-size: 18px;
  margin: 0;
  color: #738bae;
}

.form-container
  .app-container
  .components-panel
  .created-forms-container
  .saved-forms
  .form-trash-icon {
  font-size: 20px;
  color: #ff0000;
  cursor: pointer;
}

.form-container .app-container .drop-container {
  width: 75%;
  background-color: #eaeaea;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
}

.form-container .app-container .drop-container .drop-container-heading {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.form-container
  .app-container
  .drop-container
  .drop-container-heading
  .drop-heading-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-container
  .app-container
  .drop-container
  .drop-container-heading
  .drop-heading-left
  h4 {
  margin: 0;
}

.form-container
  .app-container
  .drop-container
  .drop-container-heading
  .drop-heading-left
  input {
  width: 250px;
  height: 60px;
  background: #fff;
  border: none;
  outline: none;
  border: 1px solid rgb(119, 191, 255);
  border-radius: 10px;
  padding: 10px 15px;
}

.form-container
  .app-container
  .drop-container
  .drop-container-heading
  .drop-heading-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-container
  .app-container
  .drop-container
  .drop-container-heading
  .drop-heading-right
  p {
  display: flex;
  align-items: center;
  font-size: 40px;
  gap: 10px;
  margin: 0;
  cursor: pointer;
}

.form-container
  .app-container
  .drop-container
  .drop-container-heading
  .drop-heading-right
  p:hover {
  color: #007bff;
}

.form-container
  .app-container
  .drop-container
  .drop-container-heading
  .drop-heading-right
  span {
  font-size: 18px;
}

.form-container
  .app-container
  .drop-container
  .drop-container-heading
  .drop-heading-right
  .primary-button {
  min-width: 100px;
  width: max-content;
  height: 40px;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 10px 15px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #007bff;
  color: #fff;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.form-container
  .app-container
  .drop-container
  .drop-container-heading
  .drop-heading-right
  .primary-button:hover {
  cursor: pointer;
  background: #fff;
  color: #007bff;
}

.form-container .app-container .drop-container .placeholder-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #888;
  text-align: center;
  pointer-events: none;
}

.form-container .app-container .drop-container .dropped-item {
  margin: 5px;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-container .app-container .drop-container .dropped-item .drag-icon {
  color: #738bae;
  font-size: 40px;
  font-weight: var(--thin);
  cursor: pointer;
}

.form-container .app-container .drop-container .dropped-item .component-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.component-group.rtl {
  direction: rtl;
  text-align: right;
}

.component-group.ltr {
  direction: ltr;
  text-align: left;
}

input[type="number"] {
  text-align: inherit;
}

input[type="text"],
input[type="email"] {
  text-align: inherit;
}

label {
  display: block;
  margin-bottom: 0.25rem;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  input[type="email"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  input[type="text"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  input[type="number"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  input[type="password"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  input[type="file"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  textarea,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  select {
  padding: 12px;
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #ccc;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  input[type="email"]:focus,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  input[type="text"]:focus,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  input[type="number"]:focus,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  input[type="password"]:focus,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  input[type="file"]:focus,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  textarea:focus,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  select:focus {
  border-color: #0677c8;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  select {
  width: max-content;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .form-group
  label {
  position: absolute;
  top: -12px;
  left: 12px;
  font-size: 14px;
  background: #fff;
  color: #666;
  pointer-events: none;
  transition: all 0.2s ease;
  z-index: 3;
  padding: 0 5px;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  input[type="email"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  input[type="text"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  input[type="number"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  input[type="password"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  input[type="file"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  input[type="date"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  input[type="time"] {
  width: 100%;
  height: 50px;
  background: #fff;
  border: none;
  outline: none;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px 15px;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  textarea {
  width: 100%;
  border: 1px solid #ddd;
  outline: none;
  border-radius: 5px;
  padding: 10px;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-top-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-top-group
  .top-left-group {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 0 10px 0 0;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-top-group
  .top-left-group
  input[type="text"] {
  width: 100%;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-top-group
  .top-left-group
  input[type="checkbox"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-top-group
  .top-left-group
  input[type="radio"] {
  width: 20px;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-top-group
  .top-right-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-top-group
  input {
  width: 300px;
  height: 40px;
  background: #fff;
  border: none;
  outline: none;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px 15px;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-top-group
  .top-right-group
  .trash-icon {
  color: #ff0000;
  font-size: 20px;
  cursor: pointer;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group {
  display: flex;
  align-items: center;
  gap: 40px;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  input[type="email"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  input[type="text"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  input[type="number"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  input[type="password"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  input[type="file"],
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  textarea,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  select {
  padding: 12px;
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #ccc;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  input[type="email"]:focus,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  input[type="text"]:focus,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  input[type="number"]:focus,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  input[type="password"]:focus,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  input[type="file"]:focus,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  textarea:focus,
.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  select:focus {
  border-color: #0677c8;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  select {
  width: max-content;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .form-group
  label {
  position: absolute;
  top: -12px;
  left: 12px;
  font-size: 14px;
  background: #fff;
  color: #666;
  pointer-events: none;
  transition: all 0.2s ease;
  z-index: 3;
  padding: 0 5px;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .show-image {
  width: 150px;
  height: 150px;
  padding: 10px;
  border: none;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-image-group
  .show-image
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-bottom-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-bottom-group
  .bottom-group-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-bottom-group
  .bottom-group-container
  input[type="text"] {
  width: 100%;
}

.form-container
  .app-container
  .drop-container
  .dropped-item
  .component-group
  .component-bottom-group
  .bottom-group-container
  .close-option {
  font-size: 20px;
  cursor: pointer;
}

.modal-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 300px;
}

.modal-popup-actions {
  margin-top: 20px;
}

.modal-btn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
}

.modal-btn.confirm {
  background-color: green;
  color: white;
  border: none;
}

.modal-btn.cancel {
  background-color: red;
  color: white;
  border: none;
}
