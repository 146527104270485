.preview-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
}

.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.preview-header h2 {
  margin: 0;
  font-size: 18px;
}

.preview-header .close-button {
  background: none;
  border: none;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.preview-content {
  margin-top: 10px;
}

.preview-item {
  margin-bottom: 10px;
}

.preview-item .radio-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
}

.preview-item .radio-container .option-text-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.preview-item .radio-container .option-text-container input {
  width: 40px;
}

.preview-item .radio-container .option-text-container input {
  max-width: 40px;
}

input[type="email"],
input[type="text"],
input[type="number"],
input[type="password"],
input[type="file"],
textarea,
select {
  padding: 12px;
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  transition: border-color 0.3s ease;
}

input[type="email"]:focus,
input[type="text"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="file"]:focus,
textarea:focus,
select:focus {
  border-color: #0677c8;
}

select {
  width: max-content;
}

/* label {
  position: absolute;
  top: -12px;
  left: 12px;
  font-size: 14px;
  background: #fff;
  color: #666;
  pointer-events: none;
  transition: all 0.2s ease;
  z-index: 3;
  padding: 0 5px;
} */

.form-group-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-group-container input[type="checkbox"],
.form-group-container input[type="radio"] {
  width: 20px;
}

.form-group-container input[type="text"] {
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

input:focus,
textarea:focus {
  border-color: #0677c8;
}

.preview-image-group {
  display: flex;
  align-items: center;
  gap: 40px;
}

.preview-image-group {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.preview-image-group input[type="email"],
.preview-image-group input[type="text"],
.preview-image-group input[type="number"],
.preview-image-group input[type="password"],
.preview-image-group input[type="file"],
.preview-image-group textarea,
.preview-image-group select {
  padding: 12px;
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #ccc;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.preview-image-group input:focus,
.preview-image-group textarea:focus,
.preview-image-group select:focus {
  border-color: #0677c8;
}

.preview-image-group select {
  width: max-content;
}

.preview-image-group label {
  position: absolute;
  top: -12px;
  left: 12px;
  font-size: 14px;
  background: #fff;
  color: #666;
  pointer-events: none;
  transition: all 0.2s ease;
  z-index: 3;
  padding: 0 5px;
}

.preview-image-group .show-image {
  width: 150px;
  height: 150px;
  padding: 10px;
  border: 1px solid #ddd;
}

.preview-image-group .show-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
